import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios"
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios;

Vue.mixin({
	methods: {
		/**********************************************************************
		 *  @method		: DateTime 문자열 변환 
		 *  @param      { String }
		 *  @returns    { String }      :YYYY-MM-DD HH:MM:SS
		 *********************************************************************/
		dateFormatting(dt) {
			if (!dt) {
				return "-";
			}
			const output = dt.slice(0,4) + "-" + 
						dt.slice(4, 6) + "-" + 
                        dt.slice(6,8) + " " + 
                        dt.slice(8, 10) + ":" + 
                        dt.slice(10, 12) + ":" + 
                        dt.slice(12,14);
			return output
		},

		/******************************************************************************
		 *  @method     : Get cookie
		 *  @param      { string }		:name
		 *  @returns    { string }		:value
		 *****************************************************************************/
		getCookie(name) {
			const value = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
			return value ? value[2] : null;
		},

		/**********************************************************************
		 *  @method     : Set cookie
		 *  @param      { string }		:name
		 *  @param      { string }		:value
		 *  @param      { number }		:expire(ms)
		 *  @returns    { undefined }
		 *********************************************************************/
		setCookie(name, value, exp) {
			document.cookie = name + '=' + value + ';expires=' + exp + ';path=/';
		}
	}
})
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
