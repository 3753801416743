export default [
    // 어울링이란 페이지 가드
    {
        path: "eouling",
        name: "eoulingPageGuard",
        component: () => import("../views/menu/eouling/PageGuard.vue"),
        children: [
            {
                path: "introduce",
                name: "eoulingIntroduce",
                component: () => import("../views/menu/eouling/Introduce.vue")
            },
            {
                path: "service",
                name: "eoulingService",
                component: () => import("../views/menu/eouling/ServiceInfo.vue")
            },
            {
                path: "duty",
                name: "eoulingDuty",
                component: () => import("../views/menu/eouling/Duty.vue")
            },
            {
                path: "insurance",
                name: "eoulingInsurance",
                component: () => import("../views/menu/eouling/Insurance.vue")
            },
            
        ]
    },
    {
        path: "usage",
        name: "usagePageGuard",
        component: () => import("../views/menu/usage/PageGuard.vue"),
        children: [
            {
                path: "app",
                name: "usageApp",
                component: () => import("../views/menu/usage/AppIntroduce.vue")
            },
            {
                path: "bikeUsage",
                name: "usagebikeUsage",
                component: () => import("../views/menu/usage/BikeUsage.vue")
            }
        ]
    },
    {
        path: "customer",
        name: "customerPageGuard",
        component: () => import("../views/menu/customer/PageGuard.vue"),
        children: [
            {
                path: "notice",
                name: "customerNotice",
                component: () => import("../views/menu/customer/Notice.vue")
            },
            {
                path: "notice/:id",
                name: "customerNoticeDetail",
                component: () => import("../views/menu/customer/NoticeDetail.vue")
            },
            {
                path: "faq",
                name: "customerFaq",
                component: () => import("../views/menu/customer/Faq.vue")
            },
        ]
    },

]