import Vue from 'vue'
import VueRouter from 'vue-router'
import menu from "./menu"
import errorHandling from "./error"
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'page-guard',
		component: () => import("../views/PageGuard.vue"),
		children: [
			{
				path: '',
				name: 'index',
				component: () => import("../views/Index.vue"),
			},
			{
				path: "/error",
				name: "error",
				component: () => import("../views/error/Error.vue")
			},
			...menu
		]
	},
	{
		path: "/popup",
		name: "popup",
		component: () => import("../views/PopupView.vue")
	},
	{
        path: "/stationSearch",
        name: "stationSearch",
        component: () => import("../views/menu/station/Station.vue")
    },
	...errorHandling
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/******************************************************************************
 *  동일 라우터 주소로 호출시 에러 예외 처리
 *****************************************************************************/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch( () => {});
};
export default router
